import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { parseUrl } from '../helpers/urls.helper';
import { CONFIG } from '../../environments/environment';
import { API_PATHS } from '../config/constats';
import {
  Shop,
  ShopCreate,
  ShopEdit,
  ShopTransaction,
} from '../models/shop.model';
import { GeneralResponse } from '../models/generics.model';

@Injectable({
  providedIn: 'root',
})
export class ShopsService {
  constructor(private http: HttpClient) {}

  public list(filter?): Promise<Shop[]> {
    const path = parseUrl(API_PATHS.SHOPS.LIST);
    const url = `${CONFIG.API_URL}/${path}`;
    return this.http.get<Shop[]>(url, { params: filter }).toPromise();
  }

  public add(data: ShopCreate): Promise<GeneralResponse> {
    const url = `${CONFIG.API_URL}/${API_PATHS.SHOPS.ADD}`;
    return this.http.post<GeneralResponse>(url, data).toPromise();
  }

  public update(data: ShopEdit): Promise<GeneralResponse> {
    const url = `${CONFIG.API_URL}/${API_PATHS.SHOPS.UPDATE}`;
    return this.http.post<GeneralResponse>(url, data).toPromise();
  }

  public getTransactions(shopId: string): Promise<ShopTransaction[]> {
    const path = parseUrl(API_PATHS.SHOPS.TRANSACTIONS, { shop_id: shopId });
    const url = `${CONFIG.API_URL}/${path}`;
    return this.http.get<[ShopTransaction]>(url).toPromise();
  }
}
