import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { parseUrl } from '../helpers/urls.helper';
import { CONFIG } from '../../environments/environment';
import { API_PATHS } from '../config/constats';
import { GeneralResponse } from '../models/generics.model';
import { Purchase } from '../models/purchase.model';

@Injectable({
  providedIn: 'root',
})
export class PurchasesService {
  constructor(private http: HttpClient) {}

  public list(ngoId: string, filter?): Promise<Purchase[]> {
    const path = parseUrl(API_PATHS.PURCHASES.LIST, { ngo_id: ngoId });
    const url = `${CONFIG.API_URL}/${path}`;
    return this.http.get<Purchase[]>(url, { params: filter }).toPromise();
  }

  public add(data: Purchase): Promise<GeneralResponse> {
    const url = `${CONFIG.API_URL}/${API_PATHS.PURCHASES.ADD}`;
    return this.http.post<GeneralResponse>(url, data).toPromise();
  }
}
