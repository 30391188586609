import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { parseUrl } from '../helpers/urls.helper';
import { CONFIG } from '../../environments/environment';
import { API_PATHS } from '../config/constats';
import { GeneralResponse } from '../models/generics.model';
import { Wallet } from '../models/wallet.model';

@Injectable({
  providedIn: 'root',
})
export class WalletsService {
  constructor(private http: HttpClient) {}

  public list(): Promise<Wallet[]> {
    const path = parseUrl(API_PATHS.WALLETS.LIST);
    const url = `${CONFIG.API_URL}/${path}`;
    return this.http.get<Wallet[]>(url).toPromise();
  }

  public freeze(walletAddress): Promise<GeneralResponse> {
    const data = {
      wallet_address: walletAddress,
    };
    const url = `${CONFIG.API_URL}/${API_PATHS.WALLETS.FREEZE}`;
    return this.http.post<GeneralResponse>(url, data).toPromise();
  }
}
